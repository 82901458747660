<template>
    <div class="wrapper container">
        <h1>AILaw Terms and Conditions of Use</h1>
        <div class="item">
            <p>Welcome to AILaw, a mobile app and AILaw, a website (collectively referred to as “AILaw”). The app and the site are operated by AILaw INC. (hereinafter “AILaw”) and are offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of the app and/or the site constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
            </p>
        </div>
        <div class="item">
            <h3>Nature of Service</h3>
            <p>AILaw provides an online portal to give users a general understanding of the law AILaw hosts its AILaw software as a backend service for users when they search for information. The Site includes general information on commonly encountered legal issues only for informational and educational purposes. AILaw, its Services, and its forms or templates are not a substitute for the advice or services of an attorney. AILaw, AILaw Inc., and all of AILaw’s subsidiaries, do not perform the function of an attorney or a law firm. With your informed consent, you may be directed to external service provided by a licensed attorney. This is not an act of “attorney referral service” and SmarLaw.com, AILaw, and all of its subsidiaries is not responsible for any liability that should arise from any future representation thereof. The information of lawyers published on our site and mobile applications is provided to the public free of charge and for informational purpose only. AILaw does not endorse or make any warranty as to the qualifications or competency of any attorney.
            </p>
            <p>THIS SITE AND ITS APPLICATIONS ARE NOT INTENDED TO CREATE ANY ATTORNEY-CLIENT RELATIONSHIP, AND YOUR USE OF AILaw, INCLUDING ITS SITE, APPLICATION, AND OTHER RELATED SERVICES THEREOF, DOES NOT CREATE AN ATTORNEY-CLIENT RELATIONSHIP BETWEEN YOU AND AILaw, AILaw INC., OR ANY OF ITS SUBSIDIARIES. AILaw.COM AND AILaw DO NOT REPRESENT YOU IN ANY LEGAL MATTER. INSTEAD, YOU ARE AND WILL BE REPRESENTING YOURSELF IN ANY LEGAL MATTER YOU UNDERTAKE THROUGH AILaw'S LEGAL DOCUMENT SERVICE. TO RECEIVE THE FULL PROTECTION AND BENEFITS OF THE ATTORNEY-CLIENT RELATIONSHIP, YOU ARE STRONG ADVISED TO CONSULT WITH A LICENSED LAWYER IN YOUR JURISDICTION.
            </p>
        </div>
        <div class="item">
            <h3>Disclaimer of Inaccurate and Outdated Information</h3>
            <p>AILaw strives to keep its information accurate, current and up-to-date. However, because the law changes rapidly, AILaw cannot guarantee that all of the information on the Site or Applications is completely current. The law is different from jurisdiction to jurisdiction, and may be subject to interpretation by different courts. The law is a personal matter, and no general information or legal tool like the kind AILaw provides can fit every circumstance. Furthermore, the legal information contained on the Site and Applications is not legal advice and is not guaranteed to be correct, complete or up-to-date. Therefore, if you need legal advice for your specific problem, or if your specific problem is too complex to be addressed by our tools, you are advised to consult with a licensed attorney in your jurisdiction.
            </p>
        </div>
        <div class="item">
            <h3>Your Account</h3>
            <p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that AILaw is not responsible for third party access to your account that results from theft or misappropriation of your account. AILaw and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.
            </p>
            <p>A complete statement of AILaw 's current Privacy Policy can be found by&nbsp;clicking here. Privacy Policy is expressly incorporated into this Agreement by reference.
            </p>
        </div>
        <div class="item">
            <h3>Children Under Thirteen</h3>
            <p>AILaw does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. You agree that you are at least 18 years of age and you are have the legal capacity to enter into a contract as the current one.
            </p>
        </div>
        <div class="item">
            <h3>Links to Third Party Sites/Third Party Services</h3>
            <p>AILaw may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of AILaw and AILaw is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. AILaw is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by AILaw of the site or any association with its operators.</p>
            <p>Certain services made available via AILaw are delivered by third party sites and organizations. By using any product, service or functionality originating from the AILaw domain, you hereby acknowledge and consent that AILaw may share such information and data with any third party with whom AILaw has a contractual relationship to provide the requested product, service or functionality on behalf of AILaw users and customers.
            </p>
        </div>
        <div class="item">
            <h3>No Unlawful or Prohibited Use/Intellectual Property</h3>
            <p>You are granted a non-exclusive, non-transferable, revocable license to access and use AILaw strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to AILaw that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
            <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of AILaw or its suppliers and is protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>
            <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. AILaw content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of AILaw and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of AILaw or our licensors except as expressly authorized by these Terms.</p>
            <p>AILaw has adopted a policy that provides for the immediate removal of any content, article or materials that have infringed on the rights of AILaw or of a third party or that violate intellectual property rights generally. AILaw’s policy is to remove such infringing content or materials and investigate such allegations immediately.</p>
        </div>
        <div class="item">
            <h3>Indemnification</h3>
            <p>You agree to indemnify, defend and hold harmless AILaw its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. AILaw reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with AILaw in asserting any available defenses.</p>
        </div>
        <div class="item">
            <h3>Arbitration</h3>
            <p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>
        </div>
        <div class="item">
            <h3>Class Action Waiver</h3>
            <p>Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. The parties agree that a party may bring claims against the other only in each's individual capacity, and not as a plaintiff or class member in any putative class, collective and/ or representative proceeding, such as in the form of a private attorney general action against the other. Further, unless both you and AILaw agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.</p>
        </div>
        <div class="item">
            <h3>Rights and Responsibilities</h3>
            <p>AILaw is not the publisher or author of the User Content. AILaw takes no responsibility and assumes no liability for any content posted by you or any third party.</p><p>Although we cannot make an absolute guarantee of system security, AILaw takes reasonable steps to maintain security. If you have reason to believe system security has been breached, contact us for help.</p><p>If AILaw 's technical staff finds that files or processes belonging to a member pose a threat to the proper technical operation of the system or to the security of other members, AILaw reserves the right to delete those files or to stop those processes. If the AILaw technical staff suspects a user name is being used by someone who is not authorized by the proper user, AILaw may temporarily disable that user's access in order to preserve system security. In all such cases, AILaw will contact the member as soon as feasible.</p>
            <p>AILaw has the right (but not the obligation), in our sole and absolute discretion, to edit, redact, remove, re-categorize to a more appropriate location or otherwise change any User Content.</p>
        </div>
        <div class="item">
            <h3>No Warranty</h3>
            <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. AILaw AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
            <p>AILaw AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. AILaw AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p><p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL AILaw AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF AILaw OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>
        </div>
        <div class="item">
            <h3>Termination/Access Restriction</h3>
            <p>AILaw reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of California and you hereby consent to the exclusive jurisdiction and venue of courts in California in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
            <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and AILaw as a result of this agreement or use of the Site. AILaw 's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of AILaw 's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by AILaw with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
            <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and AILaw with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and AILaw with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>
        </div>
        <div class="item">
            <h3>Changes to Terms</h3>
            <p>AILaw reserves the right, in its sole discretion, to change the Terms under which AILaw is offered. The most current version of the Terms will supersede all previous versions. AILaw encourages you to periodically review the Terms to stay informed of our updates.</p>
        </div>
        <div class="item">
            <h3>Contact Us</h3>
            <p>AILaw welcomes your questions or comments regarding the Terms:</p>
        </div>
        <div class="item contact">
            <ul>
                <li>Email Address: support@ailaw.io</li>
            </ul>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Terms"
  }
</script>

<style scoped>

</style>
